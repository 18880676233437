<template>
<div>

  <div class="logo"><a :class="{open:open}"><a @click="sideOpenClose"><img src="../images/logo.svg" alt="bookma!"></a></div>

  <div class="side-close" :class="{open:open,show:isIconShow}" @mouseenter="IconShow" @mouseleave="IconHide">
    <a @click="sideOpenClose" class="side-close__inner"></a>
  </div>

  <div class="side" @mouseenter="IconShow" @mouseleave="IconHide">
    <div class="side__top">
      <h1 class="side__bookma"><img src="../images/logo-text.svg" alt="bookma!"></h1>

      <div class="search mt-105 mb-25 mt-sp-180 mb-sp-25 center" :class="{focus:
        keyword != ''}">
        <div class="search__ico"></div>
        <input type="text" v-model="keyword" @change="changeInput" @keyup="changeInput" @keydown="changeInput" @paste="changeInput" placeholder="search">
      </div>

    </div>
    <div class="side__bottom">
      <div class="side__body">
        <h2 class="side__title"><span>感性を刺激する</span><span>Webデザインギャラリー</span><span>ブックマ！</span></h2>
      </div>
      <div class="mt-40 mt-sp-40 fs-12">
        ※現在投稿フォームを一時的に閉鎖しています。<br>
        投稿頂く場合は <a href="mailto:info@tmsb.jp" class="underline">info@tmsb.jp</a> までメールをお願いいたします。
      </div>
      <div class="mt-40 mt-sp-40">
        <ul class="side__list flex fs-12">
          <li><a href="https://x.com/bookma_org" target="_blank" class="mr-20 mr-sp-40 underline">X</a></li>
          <li><a href="mailto:info@tmsb.jp" class="underline">Contact</a></li>
        </ul>
        <p class="side__text mt-40 mt-sp-40">
          <span>Presented by</span>
          <a href="https://tmsb.jp/" target="_blank" class="side__nlp underline">TOMOSHIBI LLC.</a>
        </p>
        <p class="mt-10 mt-sp-20">© 2024 TOMOSHIBI LLC.</p>
      </div>
    </div>


  </div>

</div>
</div>
</template>

<script>
import EventHub from "../js/EventBus";
import Debounce from "../js/Debounce";

module.exports = {
  data: function() {
    return {
      open: false,
      keyword: '',
      isIconShow: false
    }
  },
  computed: {

  },
  methods: {
    sideOpenClose: function() {
      this.open = !this.open;
      this.$emit('sideopenclose');
    },
    changeInput: Debounce(function() {
      EventHub.$emit('sendkeyword', this.keyword);
    }, 1000),
    IconShow: function() {
      this.isIconShow = true;
    },
    IconHide: function() {
      this.isIconShow = false;
    },
  }

};
</script>

<style lang="scss" scoped>
@import "../sass/resouce.scss";

.logo {
    position: fixed;
    z-index: 10;
    left: 40px;
    top: 40px;
    width: 40px;
    @include media(sp) {
        @include vw(width,80);
        @include vw(top,60);
        @include vw(left,60);

    }
}

.side-close {
    position: fixed;
    z-index: 10;
    left: 0;
    top: 50%;
    width: 25px;
    height: 50px;
    overflow: hidden;
    margin-top: -25px;
    @include trans(left, 0.5s, 0s,$easeInOutCubic);

    @include media(sp) {
        @include trans(transform, 0.3s, 0s,$easeInOutCubic);
        transform: translateX(100%);
        left: auto;
        right: 0;
        @include vw(margin-top,-40);
        @include vw(width,40);
        @include vw(height,80);
    }

    &.open {
        left: 300px;
        @include media(sp) {
            transform: translateX(0);
            left: auto;
        }
    }
    &.show {
        .side-close__inner {
            transform: translateX(-60%);
            @include media(sp) {
                transform: translateX(0);
            }
        }
    }
    @include media(sp) {
        // @include vw(width,80);
        // @include vw(top,40);
        // left: 4%;
    }
    &__inner {
        @include trans(transform, 0.2s, 0s,$easeInOutCubic);
        display: block;
        position: relative;
        width: 200%;
        height: 100%;
        background-color: #000;
        border-radius: 100%;
        transform: translateX(-100%);
        cursor: pointer;
        background-image: url("../images/ico-arrow.svg");
        background-repeat: no-repeat;
        background-size: 6px;
        background-position: 75% center;
        @include media(sp) {
            @include vw(background-size,10);
            transform: translateX(0);
            background-position: 25% center;
            background-color: #2c2c2c;
        }

    }
}

.side {
    position: fixed;
    background-color: #010101;
    left: 0;
    top: 0;
    bottom: 0;
    height: 100%;
    width: 300px;
    min-height: 600px;
    overflow: scroll;
    z-index: 0;
    @include media(sp) {
        width: 100%;
    }
}

.side {
    &__title {
        // text-align: center;
        @include font(14,22,80,30,30,80);
        span {
            display: block;
            margin-bottom: 0.2em;
        }
    }
    &__bookma {
        position: absolute;
        left: 56px;
        top: 46px;
        width: 144px;

        @include media(sp) {
            @include vw(left,110);
            @include vw(top,76);
            @include vw(width,260);
        }

    }
    &__list {
        li {}
    }

    &__text {
        @include font(10,18,80,20,20,80);
        color: #aaa;
    }
    &__nlp {}
    &__top {
        position: absolute;
        // top: 40px;
        left: 40px;
        right: 40px;

        @include media(sp) {
            @include vw(left,60);
            @include vw(right,60);
        }

    }
    &__body {

        // margin: 20px 30px;
        // overflow: scroll;
        @include media(sp) {
            // @include vw(margin,180, 40);

        }

    }
    &__bottom {
        position: absolute;
        bottom: 40px;
        left: 40px;
        right: 40px;
        // width: 100%;

        @include media(sp) {
            @include vw(bottom,60);
            @include vw(left,60);
            @include vw(right,60);
        }

    }
}

.search {
    position: relative;
    input {
        position: relative;
        width: 100%;
        border: none;
        background-color: transparent;
        color: #fff;
        padding: 0.5em 10px 0.5em 30px;
        box-sizing: border-box;
        @include font(14,24,80,32,48,80);
        border-radius: 0;
        outline: none;
        border-bottom: 2px solid #626262;
        @include trans(border-bottom , 0.3s, 0s,$easeInOutCubic);
        // &:not(:placeholder-shown) {
        //     border-bottom: 2px solid #fff;
        //     & + div {
        //         opacity: 1;
        //     }
        // }
    }
    &__ico {
        width: 20px;
        height: 20px;
        position: absolute;
        left: 0;
        top: 0;
        top: 50%;
        background-image: url("../images/ico-search.svg");
        background-repeat: no-repeat;
        background-color: transparent;
        border: none;
        padding: 0;
        margin: 0;
        transform: translateY(-50%);
        opacity: 0.5;
        @include trans(opacity , 0.3s, 0s,$easeInOutCubic);
        @include media(sp) {
            @include vw(width,36);
            @include vw(height ,36);
            @include vw(right,50);
        }
    }
    &.focus,
    &:focus-within {
        input {
            border-bottom: 2px solid #fff;
        }
        .search__ico {
            opacity: 1;
        }
    }
}
</style>
