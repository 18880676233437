<template>
<div>
<!--
  <div class="button-submit" :class="{open:open}"><a @click="open = !open"></a></div>
  <transition name="fade">
    <div class="modal" v-show="open">
      <div class="modal__inner submit">

        <section class="mb-60">
          <h2 class="fs-16 mb-15 mb-sp-30 submit__title tac">サイト投稿 / メッセージ<span></span></h2>
          <form @submit="submit">
            <textarea v-model="urls" name="name" rows="8" cols="80" class="submit__textarea" placeholder="https://example.com/
https://example.net/
https://example.org/
..." required></textarea>
            <button type="submit" class="submit__button">SUBMIT</button>
            <p class="submit__text mt-15 mt-sp-20">※改行区切りで複数件の登録が可能です。</p>
          </form>
        </section>

      </div>
    </div>
  </transition>
  -->
</div>
</template>

<script>
const axios = require("axios");

module.exports = {
  data: function() {
    return {
      open: false,
      urls: "",
    }
  },
  methods: {
    submit: function(e) {
      e.preventDefault();
      const params = new URLSearchParams();
      params.append('urls', this.urls);
      this.urls = "";
      axios.post("/api/entry", params).then(response => {
        this.open = false;
      });
    },
  }
};
</script>

<style lang="scss" scoped>
@import "../sass/resouce.scss";
.button-submit {
    position: fixed;
    z-index: 12;
    right: 40px;
    top: 40px;
    width: 36px;
    height: 36px;
    @include trans(transform, 0.4s, 0s,$easeInOutCubic);
    @include media(sp) {
        @include vw(width,48);
        @include vw(height,48);
        @include vw(top,80);
        @include vw(right,64);
    }
    &.open {
        transform: rotate(45deg);
    }
    a {
        display: block;
        width: 100%;
        height: 100%;
        position: relative;
        &:after,
        &:before {
            content: '';
            position: absolute;
            top: 50%;
            left: 0;
            width: 100%;
            background-color: #fff;
            height: 2px;
        }
        &:after {
            transform: rotate(90deg);
        }
    }
}

.submit {
    width: 80%;
    max-width: 500px;
    &__title {
        font-weight: normal;
    }
    &__textarea {
        -webkit-appearance: none;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        width: 100%;
        border: none;
        display: block;
        resize: none;
        padding: 1em;
        box-sizing: border-box;
        border: 2px solid #fff;
        @include font(12,20,80,32,48,80);
        outline: none;
    }
    &__button {
        cursor: pointer;
        display: block;
        width: 100%;
        background: #000;
        color: #fff;
        border: 2px solid #fff;
        padding: 10px;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
        outline: none;
        @include font(14,14,80,24,24,80);
    }
    &__text {
        @include font(12,20,80,24,48,80);
    }
}
</style>
